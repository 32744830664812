<template>
  <div
    class="w-full flex flex-col items-center text-slate-700 pt-48"
    data-aos="fade-up"
    data-aos-duration="1200"
  >
    <h1 class="text-4xl md:text-6xl lg:text-8xl font-serif">
      Explore Our Services
    </h1>
    <h4
      class="text-xl md:text-2xl py-5 font-light text-center"
      data-aos="fade-up"
      data-aos-delay="300"
    >
      Let's transform your vision into reality, together.
    </h4>
  </div>

  <div
    class="flex justify-center pb-10"
    data-aos="zoom-in"
    data-aos-duration="1000"
  >
    <action-button />
  </div>
</template>

<script>
import ActionButton from "@/components/ActionButton.vue";
export default { name: "ExploreSection", components: { ActionButton } };
</script>

<style></style>
