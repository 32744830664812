<template>
  <div>
    <!-- Heading sections -->
    <div
      class="py-10 font-thin flex justify-center text-xl md:text-2xl"
      data-aos="fade-down"
    >
      <!-- Reduced font size for smaller screens -->
      24/7 Business Solutions
    </div>
    <div
      class="text-slate-700 font-serif flex justify-center text-4xl md:text-5xl pb-10 md:pb-20"
      data-aos="fade-up"
    >
      <!-- Adjusted font sizes and padding -->
      Explore Our Areas Of Expertise
    </div>

    <!-- Responsive grid LayOut -->
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 px-3">
      <!-- Loop through services array -->
      <div
        v-for="(service, index) in services"
        :key="index"
        :data-aos="service.aos"
        :data-aos-duration="service.duration"
      >
        <img
          class="w-full"
          :src="require(`@/assets/${service.imageSrc}`)"
          :alt="service.title"
        />
        <div
          class="w-full font-serif pt-3 md:pt-5 flex justify-center text-xl md:text-2xl text-slate-700 font-bold"
        >
          {{ service.title }}
        </div>
        <div
          class="flex justify-center from-neutral-800 font-normal text-sm md:text-base text-center md:text-left"
        >
          <a :href="service.link">Read More ......</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ExploreArea",
  data() {
    return {
      services: [
        {
          imageSrc: "strength12.jpg",
          title: "Institutional Strengthening",
          link: "/service",
          aos: "fade-up",
          duration: "1000",
        },
        {
          imageSrc: "2150917238.jpg",
          title: "Solutions",
          link: "/service",
          aos: "fade-up",
          duration: "1000",
        },
        {
          imageSrc: "workshop.jpg",
          title: "Workshop Facilitation",
          link: "/service",
          aos: "fade-up",
          duration: "1000",
        },
        {
          imageSrc: "shaking.png",
          title: "General Business Advisory",
          link: "/service",
          aos: "fade-up",
          duration: "1000",
        },
      ],
    };
  },
};
</script>
