<template>
  <div class="fixed top-0 left-0 right-0 z-50 w-full">
    <!-- Primary Navigation -->

    <!-- Additional Navigation -->
    <second-nav></second-nav>
  </div>
  <!-- Spacer to prevent content overlap -->
  <div class="h-[calc(var(--header-height) + var(--second-nav-height))]"></div>
</template>

<script>
import SecondNav from "@/components/SecondNav.vue";

export default {
  components: {
    SecondNav,
  },
};
</script>

<style scoped>
:root {
  --header-height: 64px; /* Adjust based on your header height */
  --second-nav-height: 60px; /* Adjust based on your second nav height */
}

.fixed {
  position: fixed;
  background-color: inherit;
}

.z-50 {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
</style>
