<template>
  <div>
    <header
      class="bg-logo-color sticky top-0 z-50 px-4 text-gray-600 flex justify-between items-center md:px-2"
    >
      <!-- Brand/Title -->
      <div class="text-2xl font-extralight">
        <router-link to="/" class="text-4xl font-bold"
          ><img
            :src="require('@/assets/logo.jpg')"
            alt=""
            class="w-16 h-auto md:w-6 lg:w-24"
        /></router-link>
      </div>

      <!-- Navigation for larger screens -->
      <nav class="hidden md:flex font-normal text-white text-xl space-x-6">
        <router-link to="/" class="hover:text-more-info">Home</router-link>
        <router-link to="/about" class="hover:text-more-info"
          >About</router-link
        >
        <router-link to="/service" class="hover:text-">Services</router-link>
        <router-link to="/contacts" class="hover:text-more-info"
          >Contacts</router-link
        >
      </nav>

      <!-- Toggle Button for smaller screens -->
      <div class="md:hidden">
        <button @click="toggleMenu" class="text-white hover:text-more-info">
          <!-- Font Awesome Icon -->
          <font-awesome-icon :icon="['fas', 'bars']" />
        </button>
      </div>
    </header>

    <!-- Navigation for smaller screens, toggled by the button -->
    <nav
      v-if="menuOpen"
      class="flex flex-col font-light text-xl space-y-4 px-4 py-2 bg-white"
    >
      <router-link to="/" class="hover:text-more-info" @click="toggleMenu"
        >Home</router-link
      >
      <router-link to="/about" class="hover:text-more-info" @click="toggleMenu"
        >About</router-link
      >
      <router-link
        to="/service"
        class="hover:text-more-info"
        @click="toggleMenu"
        >Services</router-link
      >
      <router-link
        to="/contacts"
        class="hover:text-more-info"
        @click="toggleMenu"
        >Contacts</router-link
      >
    </nav>
  </div>
</template>

<script>
export default {
  name: "ResponsiveNav",
  data() {
    return {
      menuOpen: false, // Menu is initially closed
    };
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen; // Toggle the menu visibility
    },
  },
};
</script>
