import { createApp } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { quillEditor } from "vue3-quill";

import {
  faFacebook,
  faInstagram,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import {
  faPhone,
  faBars,
  faEnvelope,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import App from "@/App.vue";
import "@/assets/tailwind.css";
import AOS from "aos";
import "aos/dist/aos.css";
import router from "@/router";
import store from "./store";

// Initialize AOS
AOS.init({
  duration: 1000,
  once: true,
});

// Set up FontAwesome
library.add(
  faFacebook,
  faInstagram,
  faWhatsapp,
  faPhone,
  faBars,
  faEnvelope,
  faLocationDot
);

const app = createApp(App).use(store);

// Register QuillEditor and other plugins
app.use(router);
app.use(quillEditor);

// Register FontAwesome component globally
app.component("font-awesome-icon", FontAwesomeIcon);

app.mount("#app");
