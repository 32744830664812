<template>
  <div>
    <div
      class="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-16 mx-4 md:mx-16 text-slate-700"
    >
      <div
        v-for="(service, index) in services"
        :key="index"
        class="text-center bg-gray-100 p-6 md:p-10 transition-transform duration-600 hover:transform hover:-translate-y-2 hover:shadow-2xl hover:bg-green-100 hover:text-gray-900"
      >
        <h2 class="text-2xl md:text-3xl font-serif mb-2 font-bold">
          {{ service.title }}
        </h2>
        <p class="text-sm md:text-base font-normal">
          {{ service.description }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServicesTypes",
  data() {
    return {
      services: [
        {
          title: "Institutional Strengthening",
          description: `
            Elevate your business vision with our tailored Business Plan solutions.
            Our expertise lies in crafting strategic roadmaps, identifying growth
            opportunities, and refining your business model for optimal performance.
            Let us guide you towards a clear and actionable plan that sets the
            foundation for your business's success.
          `,
        },
        {
          title: "Solutions",
          description: `
            Empower your business vision with our tailored Business Plan solutions.
            Our expertise lies in crafting strategic roadmaps, identifying growth
            opportunities, and refining your business model for optimal performance.
            Let us guide you towards a clear and actionable plan that sets the
            foundation for your business's success.
          `,
        },
        {
          title: "Workshop Facilitation",
          description: `
            Facilitate transformative experiences with our Workshop Facilitation
            services. We specialize in orchestrating engaging and productive
            workshops tailored to your specific needs. Let us guide your team
            towards meaningful insights, collaboration, and actionable outcomes that
            drive progress and innovation.
          `,
        },
        {
          title: "General Business Advisory",
          description: `
            Unleash the full potential of your business with our comprehensive
            General Business Advisory services. Our commitment lies in enhancing
            your organization's governance, streamlining processes, and instilling a
            culture of continuous improvement. Trust us to guide you towards
            building a stronger, more adaptable institution ready to achieve
            sustained success.
          `,
        },
        {
          title: "Accountancy Service",
          description: `
            Empower your business vision with our tailored Business Plan solutions.
            Our expertise lies in crafting strategic roadmaps, identifying growth
            opportunities, and refining your business model for optimal performance.
            Let us guide you towards a clear and actionable plan that sets the
            foundation for your business's success.
          `,
        },
        {
          title: "HR Solutions",
          description: `
            Facilitate transformative experiences with our Workshop Facilitation
            services. We specialize in orchestrating engaging and productive
            workshops tailored to your specific needs. Let us guide your team
            towards meaningful insights, collaboration, and actionable outcomes that
            drive progress and innovation.
          `,
        },
      ],
    };
  },
};
</script>

<style scoped>
/* Additional styles can be added here if needed */
</style>
