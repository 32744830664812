<template>
  <div class="container mx-auto px-4">
    <!-- First Section -->
    <div class="flex flex-col md:flex-row items-center gap-4 p-4">
      <!-- Yellow bar on the left side -->
      <div class="w-full md:w-1 bg-yellow-400" data-aos="slide-down"></div>

      <div class="flex-1 p-4">
        <div class="text-2xl font-extralight mx-4 sm:mx-10 pt-16">
          <!-- Main content -->
          <p class="pl-2 py-3" data-aos="fade-up">Stay In Touch</p>
          <p
            class="text-3xl sm:text-4xl lg:text-5xl xl:text-6xl font-serif text-logo-color"
            data-aos="zoom-in"
          >
            Talk To Us
          </p>

          <!-- Content description -->
          <p class="text-base sm:text-lg text-gray-600 pt-2" data-aos="fade-up">
            We value our clients and are dedicated to providing exceptional
            service. Reach out to us, and we'll ensure you receive the best
            support and solutions tailored to your needs.
          </p>
        </div>
      </div>

      <div class="flex-1 flex justify-center items-center p-4">
        <img
          :src="require('@/assets/intouch.png')"
          alt="Contact Us Image"
          class="md:h-auto max-w-full"
        />
      </div>
    </div>

    <!-- Second Section -->
    <div class="flex flex-col md:flex-row items-center gap-4 p-4">
      <!-- Yellow bar on the left side -->
      <div class="w-full md:w-1 bg-yellow-400" data-aos="slide-down"></div>

      <div class="flex-1 flex justify-center items-center p-4">
        <img
          :src="require('@/assets/strength.jpg')"
          alt="Strength Image"
          class="md:h-auto max-w-full"
        />
      </div>

      <div class="flex-1 p-4">
        <div class="text-2xl font-extralight mx-4 sm:mx-10 pt-16">
          <!-- Main content -->

          <!-- Content description -->
          <p class="text-base sm:text-lg text-gray-950 pt-2" data-aos="fade-up">
            We are dedicated to empowering organizations through comprehensive
            institutional strengthening. Our mission is to help you build a
            robust institution that excels in its field by addressing your
            unique objectives and challenges. We craft detailed business plans
            tailored to your goals, providing a strategic roadmap that drives
            growth and navigates industry complexities. Our workshop
            facilitation enhances team dynamics and fosters innovation, helping
            you develop actionable strategies and improve collaboration. Our
            general business advisory services offer expert guidance to overcome
            challenges and seize opportunities, ensuring your organization
            thrives in a competitive environment. By partnering with us, you
            invite a dedicated ally into your journey—one committed to your
            long-term success. We offer unwavering support and expert advice to
            help you strengthen your institution and achieve your strategic
            goals. Connect with us today, and together, let's build a resilient
            and successful future.
          </p>
        </div>
      </div>
    </div>

    <div>
      <ask-as></ask-as>
      <footter-orson></footter-orson>
      <sub-footter></sub-footter>
    </div>
  </div>
</template>

<script>
import FootterOrson from "@/components/FootterOrson.vue";
import SubFootter from "@/components/SubFootter.vue";
import AskAs from "../AskAs.vue";

export default {
  components: { FootterOrson, SubFootter, AskAs },
};
</script>

<style scoped>
/* Optional: Add any additional scoped styles if needed */
/* Tailwind classes are used inline, so scoped styles are minimal */
</style>
