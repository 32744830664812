<template>
  <div class="flex flex-col-reverse md:flex-row py-10">
    <div class="w-full md:w-1/2 p-4">
      <div
        class="flex items-center justify-center relative"
        data-aos="fade-right"
        data-aos-delay="400"
      >
        <img
          class="max-w-full h-auto md:h-full"
          :src="require('@/assets/Building.jpg')"
          alt="AEMG Ltd."
        />
      </div>
    </div>
    <div class="w-full md:w-1/2 px-4 md:px-16 pt-6 md:pt-40 text-slate-700">
      <!-- Yellow line above the heading -->
      <div class="h-1 bg-yellow-400 mb-2 w-full" data-aos="fade-down"></div>
      <div class="relative">
        <!-- Right column content -->
        <h2 class="text-2xl font-thin">What We Do Best</h2>
        <p class="text-4xl md:text-8xl font-serif">Areas Of Expertise</p>
        <!-- Yellow line below the paragraph -->

        <p class="text-base md:text-lg font-normal p-5">
          At Orsonrede Consulting, we specialize in strategic financial and
          business consulting services. Our team excels in financial analysis,
          investment advisory, risk management, and customized business
          solutions. With a profound understanding of banking and finance, we
          collaborate closely with you to navigate challenges and seize
          opportunities in today's dynamic business landscape. Count on us to
          provide insightful strategies and actionable recommendations that
          propel your business growth and financial success.
        </p>
        <div class="h-1 bg-yellow-400 w-full" data-aos="fade-up"></div>
      </div>
      <!-- Yellow line below the content -->
    </div>
  </div>
</template>

<script>
export default {
  // Your component logic goes here
};
</script>

<style scoped>
/* Any additional scoped styles can be added here */
</style>
