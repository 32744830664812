<template>
  <contact-hero />
</template>

<script>
import ContactHero from "@/components/ContactDir.vue/ContactHero.vue";
export default { name: "ContactsView", components: { ContactHero } };
</script>

<style></style>
