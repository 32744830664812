<template>
  <div class="pt-4 text-center sm:text-left">
    <!-- Center align text for smaller screens -->
    <button
      class="action-button bg-more-info py-2 px-5 text-white transition duration-300 hover:bg-logo-color flex items-center justify-center sm:justify-start"
      @click="handleClick"
    >
      <slot>More Info.</slot>
      <font-awesome-icon
        class="text-sm px-1 sm:text-xl"
        :icon="['fas', 'arrow-right-long']"
      />
    </button>
  </div>
</template>

<script>
export default {
  name: "ActionButton",
  methods: {
    handleClick() {
      console.log("Button clicked");
    },
  },
};
</script>

<style>
/* Media query for responsive adjustments */
@media (max-width: 640px) {
  /* Example breakpoint for small screens */
  .action-button {
    padding: 1rem 2rem; /* Increased padding for small screens */
    font-size: 1.25rem; /* Reduced font size for smaller screens */
  }
}

@media (min-width: 641px) {
  .action-button {
    padding: 0.5rem 1.5rem; /* Normal padding for larger screens */
    font-size: 1.75rem; /* Default font size */
  }
}
</style>
