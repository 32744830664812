<template>
  <div class="grid grid-cols-1 md:grid-cols-2 pt-3">
    <!-- Column 1: Image -->
    <!-- <div class="bg-black px-0">
      <img class="w-full h-fit" :src="require('@/assets/aG.jpg')" alt="Chair" />
    </div> -->

    <!-- Column 2: Title and Form -->
    <div class="flex flex-col justify-start px-2 bg-orange-100">
      <!-- Centered Title -->
      <div class="font-serif text-5xl text-center pt-8 text-slate-700">
        <!-- Center the title -->
        Ask Us Anything, Anytime
      </div>

      <!-- Contact Form -->
      <form @submit.prevent="handleSubmit" class="w-full md:w-3/4 py-4 mx-auto">
        <!-- Adjusted width and center alignment -->

        <!-- Full Name and Email -->
        <div
          class="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4"
        >
          <div class="w-full md:w-1/2">
            <label for="name" class="block text-xl font-serif text-slate-700">
              Full Name
            </label>
            <input
              v-model="name"
              type="text"
              id="name"
              name="name"
              placeholder="Full Name"
              class="w-full p-2 border border-slate-700 text-slate-700"
              required
            />
          </div>

          <div class="w-full md:w-1/2">
            <label for="email" class="block text-xl font-serif text-slate-700">
              Email
            </label>
            <input
              v-model="email"
              type="email"
              id="email"
              name="email"
              placeholder="Email"
              class="w-full p-2 border border-slate-700 text-slate-700"
              required
            />
          </div>
        </div>

        <!-- Phone and Subject -->
        <div
          class="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 mt-4"
        >
          <div class="w-full md:w-1/2">
            <label for="phone" class="block text-xl font-serif text-slate-700">
              Phone Number
            </label>
            <input
              v-model="phone"
              type="tel"
              id="phone"
              name="phone"
              placeholder="Phone Number"
              class="w-full p-2 border border-slate-700 text-slate-700"
              required
              pattern="\d*"
            />
          </div>

          <div class="w-full md:w-1/2">
            <label
              for="subject"
              class="block text-xl font-serif text-slate-700"
            >
              Subject of Inquiry
            </label>
            <input
              v-model="subject"
              type="text"
              id="subject"
              name="subject"
              placeholder="Subject of Inquiry"
              class="w-full p-2 border border-slate-700 text-slate-700"
              required
            />
          </div>
        </div>

        <!-- Message and Submit -->
        <div class="mt-4">
          <label for="message" class="block text-xl font-serif text-slate-700">
            Tell Us More About Your Inquiry
          </label>
          <textarea
            v-model="message"
            id="message"
            name="message"
            placeholder="Tell Us More About Your Inquiry"
            class="w-full p-2 border border-slate-700 text-slate-700"
            rows="4"
          ></textarea>
        </div>

        <!-- Submit Button -->
        <div class="mt-4 flex justify-center">
          <button
            type="submit"
            class="bg-more-info py-2 px-5 text-white transition duration-300 hover:bg-logo-color"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "AskUs",
  methods: {
    handleSubmit() {
      console.log("Form submitted");
    },
  },
};
</script>

<style scoped>
/* Additional scoped styles for responsive design */
</style>
