<template><main-nav></main-nav></template>

<script>
import MainNav from "@/components/MainNav.vue";
export default {
  components: { MainNav },
};
</script>

<style></style>
