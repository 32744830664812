<template>
  <footer class="bg-white text-slate-700 text-center py-4">
    <p>Ⓒ 2024 - All Rights Are Reserved</p>
    <p class="text-sm">
      Designed by Jewel
      <font-awesome-icon :icon="['fas', 'gem']" class="pr-1" />
    </p>
  </footer>
</template>

<script>
export default { name: "SubFootter" };
</script>

<style scoped>
/* Additional styling for the footer */
footer p {
  margin: 0;
  font-size: 0.875rem; /* Smaller font size for designer credit */
}
</style>
