<template>
  <service-hero />
</template>

<script>
import ServiceHero from "@/components/ServiceDir/ServiceHero.vue";
export default {
  name: "ServiceView",
  components: { ServiceHero },
};
</script>

<style></style>
