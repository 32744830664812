<template>
  <div class="container mx-auto py-28 px-4 sm:pt-20 sm:px-6">
    <!-- Main section -->
    <div class="text-center py-20">
      <!-- Catchphrase -->
      <p class="text-lg sm:text-2xl font-thin py-3">
        When It's Critical, You Need the Best—Choose Us.
      </p>

      <!-- Heading with responsive font size -->
      <p class="text-5xl sm:text-9xl font-serif text-logo-color py-1">
        Why Choose Us
      </p>

      <!-- Description with adjusted text size and line break -->
      <p class="text-sm sm:text-2xl font-light py-3">
        Our focus on reliability, creativity, and client satisfaction sets us
        apart.
        <br class="hidden sm:block" />
        We deliver tailored solutions that truly meet your needs.
      </p>
    </div>

    <!-- Three-column section -->
    <div class="container mx-auto px-4 sm:px-6 lg:px-8 py-20">
      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 pt-6">
        <!-- Column 1 -->
        <div
          class="bg-gray-200 p-6 rounded-lg shadow-lg transition-transform duration-500 hover:scale-105 hover:shadow-xl hover:bg-gray-300 transform-gpu"
        >
          <h2 class="text-7xl font-semibold text-logo-color">01</h2>
          <p class="mt-2 text-gray-600 py-10 font-serif text-2xl font-bold">
            Strategist
          </p>
          <p class="font-normal text-2xl">
            We design detailed and tailored business plans that align with your
            unique goals. Our strategic approach ensures that you navigate
            challenges effectively and drive sustainable growth
          </p>
        </div>

        <!-- Column 2 (slightly lower with a negative margin) -->
        <div
          class="bg-gray-200 p-6 rounded-lg shadow-lg transition-transform duration-500 hover:scale-105 hover:shadow-xl hover:bg-gray-300 -mt-4 transform-gpu"
        >
          <!-- Negative margin creates the valley effect -->
          <h2 class="text-7xl font-semibold text-logo-color">02</h2>
          <p class="mt-2 text-gray-600 py-10 font-serif text-2xl font-bold">
            Professionals
          </p>

          <p class="font-normal text-2xl">
            Our team of seasoned professionals offers a wealth of expertise and
            industry knowledge. We deliver innovative solutions and practical
            advice that enhance your business operations and lead to tangible
            results.
          </p>
        </div>

        <!-- Column 3 -->
        <div
          class="bg-gray-200 p-6 rounded-lg shadow-lg transition-transform duration-500 hover:scale-105 hover:shadow-xl hover:bg-gray-300 transform-gpu"
        >
          <h2 class="text-7xl font-semibold text-logo-color">03</h2>
          <p class="mt-2 text-gray-600 font-serif font-bold text-2xl py-10">
            Experienced
          </p>
          <p class="font-normal text-2xl">
            With years of experience across various business sectors, we provide
            reliable and actionable guidance. Our deep understanding of diverse
            business needs helps us offer solutions that support your long-term
            success and adaptability
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WhyChooseUs",
};
</script>

<style scoped>
/* Custom styles for smooth scaling and shadow effect */
</style>
