<template>
  <hero-page />
</template>

<script>
import HeroPage from "@/components/HeroPage.vue";
export default {
  name: "HomeView",
  components: { HeroPage },
};
</script>
