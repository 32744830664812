<template>
  <div class="container pt-10 mx-auto px-4 sm:px-6 lg:px-8">
    <div class="flex justify-center p-8">
      <h1 class="font-serif text-slate-700 text-5xl">
        Frequently Asked Questions & Answers
      </h1>
    </div>
    <div class="flex justify-center pb-20">
      <!-- Column 1 -->
      <div class="w-1/2 p-4">
        <!-- Yellow bar at the top, animated with AOS -->
        <div class="h-1 bg-yellow-400 mb-2 w-full" data-aos="slide-right"></div>

        <div class="faq-item py-4" :class="{ open: isOpen[1] }">
          <div
            class="flex items-center justify-between cursor-pointer"
            @click="toggleAnswer(1)"
          >
            <h2
              class="font-semibold text-2xl font-mono text-logo-color"
              data-aos="fade-right"
              data-aos-duration="500"
            >
              How Fast Can Your Team Respond?
            </h2>
            <svg
              class="w-6 h-6 text-gray-600 transition-transform duration-300"
              :class="{ 'rotate-180': isOpen[1] }"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 9l-7 7-7-7"
              ></path>
            </svg>
          </div>
          <transition name="slide">
            <div
              v-if="isOpen[1]"
              class="mt-2 text-gray-700 text-lg"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              Answer 1. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Sed convallis ultricies magna, sit amet aliquet neque consequat
              at.
            </div>
          </transition>
        </div>

        <!-- Yellow bar at the bottom -->
        <div class="h-1 bg-yellow-400 mt-2 w-full" data-aos="slide-left"></div>

        <!-- FAQ Item 3 -->
        <div class="faq-item py-4" :class="{ open: isOpen[3] }">
          <div
            class="flex items-center justify-between cursor-pointer"
            @click="toggleAnswer(3)"
          >
            <h2
              class="font-semibold text-2xl"
              data-aos="fade-right"
              data-aos-duration="500"
            >
              What Are Your Working Hours?
            </h2>
            <svg
              class="w-6 h-6 text-gray-600 transition-transform duration-300"
              :class="{ 'rotate-180': isOpen[3] }"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 9l-7 7-7-7"
              ></path>
            </svg>
          </div>
          <transition name="slide">
            <div
              v-if="isOpen[3]"
              class="mt-2 text-gray-700"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              Answer 3. Our team operates from 9 AM to 6 PM, Monday to Friday.
            </div>
          </transition>
        </div>

        <!-- Yellow bar at the bottom -->
        <div class="h-1 bg-yellow-400 mt-2 w-full" data-aos="slide-left"></div>

        <!-- FAQ Item 5 -->
        <div class="faq-item py-4" :class="{ open: isOpen[5] }">
          <div
            class="flex items-center justify-between cursor-pointer"
            @click="toggleAnswer(5)"
          >
            <h2
              class="font-semibold text-2xl"
              data-aos="fade-right"
              data-aos-duration="500"
            >
              Can I Get a Custom Quote?
            </h2>
            <svg
              class="w-6 h-6 text-gray-600 transition-transform duration-300"
              :class="{ 'rotate-180': isOpen[5] }"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 9l-7 7-7-7"
              ></path>
            </svg>
          </div>
          <transition name="slide">
            <div
              v-if="isOpen[5]"
              class="mt-2 text-gray-700"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              Answer 5. Yes, you can request a custom quote by filling out the
              form on our contact page.
            </div>
          </transition>
        </div>

        <!-- Yellow bar at the bottom -->
        <div class="h-1 bg-yellow-400 mt-2 w-full" data-aos="slide-left"></div>
      </div>

      <!-- Column 2 -->
      <div class="w-1/2 p-4">
        <!-- Yellow bar at the top, animated with AOS -->
        <div class="h-1 bg-yellow-400 mb-2 w-full" data-aos="slide-right"></div>

        <div class="faq-item py-4" :class="{ open: isOpen[2] }">
          <div
            class="flex items-center justify-between cursor-pointer"
            @click="toggleAnswer(2)"
          >
            <h2
              class="font-semibold text-2xl"
              data-aos="fade-right"
              data-aos-duration="500"
            >
              What Services Do You Offer?
            </h2>
            <svg
              class="w-6 h-6 text-gray-600 transition-transform duration-300"
              :class="{ 'rotate-180': isOpen[2] }"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 9l-7 7-7-7"
              ></path>
            </svg>
          </div>
          <transition name="slide">
            <div
              v-if="isOpen[2]"
              class="mt-2 text-gray-700"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              Answer 2. We offer web development, app development, and digital
              marketing services.
            </div>
          </transition>
        </div>

        <!-- Yellow bar at the bottom -->
        <div class="h-1 bg-yellow-400 mt-2 w-full" data-aos="slide-left"></div>

        <!-- FAQ Item 4 -->
        <div class="faq-item py-4" :class="{ open: isOpen[4] }">
          <div
            class="flex items-center justify-between cursor-pointer"
            @click="toggleAnswer(4)"
          >
            <h2
              class="font-semibold text-2xl"
              data-aos="fade-right"
              data-aos-duration="500"
            >
              How Do I Contact Support?
            </h2>
            <svg
              class="w-6 h-6 text-gray-600 transition-transform duration-300"
              :class="{ 'rotate-180': isOpen[4] }"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 9l-7 7-7-7"
              ></path>
            </svg>
          </div>
          <transition name="slide">
            <div
              v-if="isOpen[4]"
              class="mt-2 text-gray-700"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              Answer 4. You can contact our support team via email at
              support@example.com or call us at (123) 456-7890.
            </div>
          </transition>
        </div>

        <!-- Yellow bar at the bottom -->
        <div class="h-1 bg-yellow-400 mt-2 w-full" data-aos="slide-left"></div>

        <!-- FAQ Item 6 -->
        <div class="faq-item py-4" :class="{ open: isOpen[6] }">
          <div
            class="flex items-center justify-between cursor-pointer"
            @click="toggleAnswer(6)"
          >
            <h2
              class="font-semibold text-2xl"
              data-aos="fade-right"
              data-aos-duration="500"
            >
              Do You Provide Ongoing Maintenance?
            </h2>
            <svg
              class="w-6 h-6 text-gray-600 transition-transform duration-300"
              :class="{ 'rotate-180': isOpen[6] }"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 9l-7 7-7-7"
              ></path>
            </svg>
          </div>
          <transition name="slide">
            <div
              v-if="isOpen[6]"
              class="mt-2 text-gray-700"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              Answer 6. Yes, we offer ongoing maintenance and support services
              for all our clients.
            </div>
          </transition>
        </div>

        <!-- Yellow bar at the bottom -->
        <div class="h-1 bg-yellow-400 mt-2 w-full" data-aos="slide-left"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOpen: {
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
      },
    };
  },
  methods: {
    toggleAnswer(id) {
      this.isOpen[id] = !this.isOpen[id];
    },
  },
};
</script>

<style scoped>
.faq-item {
  overflow: hidden;
}

.slide-enter-active,
.slide-leave-active {
  transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
}

.slide-enter,
.slide-leave-to {
  max-height: 0;
  opacity: 0;
}

.slide-enter-to,
.slide-leave {
  max-height: 500px; /* Adjust as needed */
  opacity: 1;
}
</style>
