<template>
  <div>
    <explore-section /><img
      class="w-full h-80"
      :src="require('@/assets/coins11.jpg')"
      alt=""
    />
    <explore-area />

    <our-clients /> <AskAs /><footter-orson /><sub-footter />
  </div>
</template>

<script>
import OurClients from "@/components/ServiceDir/OurClients.vue";
import ExploreSection from "@/components/ExploreSection.vue";
import SubFootter from "@/components/SubFootter.vue";
import AskAs from "@/components/AskAs.vue";
import ExploreArea from "@/components/ExploreArea.vue";
import FootterOrson from "@/components/FootterOrson.vue";

export default {
  data() {
    return {
      name: "",
      email: "",
      phone: "",
      subject: "",
      message: "", // New data property for textarea
    };
  },
  methods: {
    handleSubmit() {
      console.log("Form Submitted");
      console.log("Name:", this.name);
      console.log("Email:", this.email);
      console.log("Phone:", this.phone);
      console.log("Subject:", this.subject);
      console.log("Message:", this.message);
    },
  },
  components: {
    ExploreSection,

    AskAs,
    ExploreArea,
    SubFootter,
    FootterOrson,
    OurClients,
  },
};
</script>

<style scoped>
/* Custom styles, if needed */
</style>
