<template>
  <div class="container px-4 text-white bg-logo-color max-w-full py-12">
    <div
      class="flex flex-col md:flex-row justify-between bg-logo-color space-y-4 md:space-y-0"
      data-aos="fade-in"
    >
      <div
        class="w-full md:w-1/4 text-center font-thin text-xl"
        data-aos="fade-right"
        data-aos-duration="800"
        data-aos-delay="400"
      >
        <div>
          <font-awesome-icon class="pr-1" :icon="['fas', 'location-dot']" />
          Address
        </div>
        <div class="pt-4 md:pt-3">Bubble LN Asofan, Accra</div>
      </div>
      <div
        class="w-full md:w-1/4 text-center font-thin text-xl"
        data-aos="fade-up"
        data-aos-duration="800"
        data-aos-delay="600"
      >
        <div>
          <font-awesome-icon class="pr-1" :icon="['fas', 'phone']" /> Phone
        </div>
        <div class="pt-2 md:pt-3">
          <a href="tel:+233558374161">+233-558-374-161</a>
        </div>
      </div>
      <div
        class="w-full md:w-1/4 text-center font-thin text-xl"
        data-aos="fade-left"
        data-aos-duration="800"
        data-aos-delay="800"
      >
        <div>
          <font-awesome-icon class="pr-1" :icon="['fas', 'envelope']" /> Email
        </div>
        <div class="pt-2 md:pt-3">
          <a href="mailto:info@orsonrede.com">info@orsonrede.com</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterOrson",
};
</script>

<style scoped>
.bg-logo-color {
  background-color: rgb(22, 67, 100); /* Adjusted for your custom color */
}

img {
  max-width: 100%;
  height: auto;
}
</style>
