<template>
  <div class="p-4 md:p-16 text-slate-700">
    <div
      class="text-xl md:text-2xl p-8 font-light flex items-center justify-center py-3"
      data-aos="fade-up"
    >
      Meet Our Clients
    </div>
    <div
      class="text-4xl md:text-5xl font-serif flex items-center justify-center"
      data-aos="zoom-in"
    >
      Our Client Portfolio
    </div>
    <div class="grid grid-cols-2 md:grid-cols-4 gap-8 md:gap-28 mt-10">
      <!-- Client logos -->
      <div
        class="flex items-center justify-center relative"
        data-aos="fade-right"
      >
        <a href="https://fnlhub.org/">
          <img
            class="client-logo"
            :src="require('@/assets/logo.webp')"
            alt="FASTNETLINKS"
          />
        </a>
      </div>
      <div
        class="flex items-center justify-center relative"
        data-aos="fade-left"
        data-aos-delay="200"
      >
        <a href="https://mastercardfdn.org/">
          <img
            class="client-logo"
            :src="require('@/assets/Mastercard_Foundation.jpg')"
            alt="Mastercard Foundation"
          />
        </a>
      </div>
      <div
        class="flex items-center justify-center relative"
        data-aos="fade-right"
        data-aos-delay="400"
      >
        <a href="https://aemgltd.com/">
          <img
            class="client-logo"
            :src="require('@/assets/aemg_logo.png')"
            alt="AEMG Ltd."
          />
        </a>
      </div>
      <div
        class="flex items-center justify-center relative"
        data-aos="fade-left"
        data-aos-delay="600"
      >
        <h1 class="text-2xl md:text-4xl font-bold text-center">
          MEINAS GHANA COMPANY LIMITED
        </h1>
      </div>
      <!-- Add more client logos here -->
    </div>
  </div>
</template>

<script>
export default {
  name: "ClientPortfolio",
};
</script>

<style scoped>
/* Style for logos */
.client-logo {
  width: 100px; /* Set a fixed width */
  height: auto; /* Maintain aspect ratio */
  filter: grayscale(100%); /* Make black and white */
  transition: filter 0.3s ease, transform 0.3s ease; /* Smooth hover effects */
}

.client-logo:hover {
  filter: grayscale(0); /* Revert to colored on hover */
  transform: scale(1.1); /* Slight zoom on hover */
}
</style>
