<template>
  <do-best /><services-types /><ask-as /><footter-orson /><sub-footter />
</template>

<script>
import AskAs from "@/components/AskAs.vue";
import FootterOrson from "@/components/FootterOrson.vue";
import SubFootter from "@/components/SubFootter.vue";

import DoBest from "@/components/ServiceDir/DoBest.vue";
import ServicesTypes from "@/components/ServiceDir/ServicesTypes.vue";

export default {
  components: {
    SubFootter,
    FootterOrson,
    AskAs,
    DoBest,
    ServicesTypes,
  },
  name: "ServiceHero",
};
</script>

<style></style>
