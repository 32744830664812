<template>
  <div class="container pt-20 mx-auto px-4 sm:px-6 lg:px-8">
    <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
      <!-- Left side with text and bars -->
      <div class="text-2xl font-extralight mx-4 sm:mx-10 pt-16">
        <!-- Yellow bar at the top, animated with AOS -->
        <div class="h-1 bg-yellow-400 mb-2 w-full" data-aos="slide-right"></div>

        <!-- Main content -->
        <p class="pl-2 py-3" data-aos="fade-up">Learn About Our History</p>
        <p
          class="text-4xl sm:text-8xl font-serif text-logo-color"
          data-aos="zoom-in"
        >
          Over a Decade of Experience
        </p>

        <!-- Yellow bar at the bottom -->
        <p class="text-lg text-gray-600 pt-2" data-aos="fade-up">
          With over 10 years of industry expertise, we have a proven track
          record of excellence and a commitment to delivering quality results.
        </p>
        <div class="h-1 bg-yellow-400 mt-2 w-full" data-aos="slide-left"></div>
      </div>

      <!-- Right side with image -->
      <div class="flex justify-center sm:justify-end" data-aos="fade-in">
        <img
          :src="require('@/assets/about2.jpg')"
          alt="About us"
          class="w-full sm:w-3/4 lg:w-full rounded-lg shadow-lg"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DecadeExpi",
};
</script>

<style scoped>
/* Custom styles for your component, if needed */
</style>
